import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import docService from "../services/docService";
import SignatureCanvas from "react-signature-canvas";

const DocSign = ({}) => {
  // const [showComingSoon, setShowComingSoon] = useState(false);
  const params = useParams();
  console.log(params.signatureID);
  const signatureID = params.signatureID;
  console.log(`signatureID = `, signatureID);
  let sigPad = {};
  // console.log(signatureSignaturePad);
  const [signatureSaved, setSignatureSaved] = useState(false);
  console.log("signature already signed?", signatureSaved);
  const [signatureError, setSignatureError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [currDoc, setCurrDoc] = useState();

  const findDoc = async () => {
    const { data: currentDoc } = await docService.findDoc(signatureID);
    console.log(`CurrDoc = `, currentDoc);
    setCurrDoc(currentDoc);

    if (currentDoc.signature) {
      setSignatureSaved(true);
    }
  };

  const clearCanvas = () => {
    sigPad.clear();
    setSignatureError(false);
  };

  const saveSig = async () => {
    if (!sigPad.isEmpty()) {
      const data = await docService.saveDocSignature(signatureID);
      console.log(data);
    }

    if (!sigPad.isEmpty()) {
      setSignatureError(false);
      // setDocSignature(sigPad.getTrimmedCanvas().toDataURL("image/jpeg"));
      const data = await docService.saveDocSignature();
      console.log(`data = `, data);

      const { data: saveResponse } = await docService.saveDocSignature(
        signatureID,
        sigPad.getTrimmedCanvas().toDataURL("image/png")
      );
      if (saveResponse === "Signature Saved") {
        setCurrDoc({
          ...currDoc,
          signature: sigPad.getTrimmedCanvas().toDataURL("image/png"),
        });
        setSignatureSaved(true);
      } else {
        setSignatureError(true);
        setErrorMessage(saveResponse);
      }
    } else {
      setSignatureError(true);
      setErrorMessage("Please Provide Signature");
    }
  };

  useEffect(() => {
    try {
      findDoc(signatureID);
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div className="content">
      {currDoc && (
        <div>
          <h1>{currDoc.signatureTitle}</h1>
          <p>Name: {currDoc.signatureUserName}</p>
          <p>Company: {currDoc.signatureCompany}</p>
          <hr />
          <p className="disclaimer">
            The signature below indicates my agreement with the associated
            document. I agree that what I sign is representative of my
            signature.
          </p>
        </div>
      )}
      {!signatureSaved ? (
        <>
          <div>
            <SignatureCanvas
              ref={(ref) => {
                sigPad = ref;
              }}
              penColor="#0000c2"
              backgroundColor="white"
              canvasProps={{ className: "sigCanvas" }}
            />
          </div>
          {signatureError && (
            <p className="alert alert-danger">{errorMessage}</p>
          )}
          <div className="btn-bar">
            <button className="sc-btn" onClick={saveSig}>
              Save
            </button>
            <button className="sc-btn" onClick={clearCanvas}>
              Clear
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="alert alert-success">
            <p>Signature Saved</p>
          </div>
        </>
      )}
    </div>
  );
};

export default DocSign;
