import http from "./httpService";
// import { apiUrl } from "../config.json";
import apiUrl from "../config.json";

export async function findDoc(docID) {
    const apiEndpoint = apiUrl.apiUrl + "/findSignature";
    const signatureResponse = await http.post(apiEndpoint, { signatureID: docID });
    return signatureResponse;
}

export async function saveDocSignature(docID, signature) {
    const apiEndpoint = apiUrl.apiUrl + "/saveSignature";
    const signatureResponse = await http.post(apiEndpoint, { signatureID: docID, signature: signature });
    return signatureResponse;
}


export default {
    findDoc,
    saveDocSignature
}