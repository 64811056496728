import SembraCareLogo from "../../assets/images/sembracare-logo.png";

function Header() {
  const imgStyle = {
    height: "40px",
  };

  return (
    <header className="header">
      <img src={SembraCareLogo} alt="SembraCare Logo" style={imgStyle}></img>
    </header>
  );
}

export default Header;
