import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';

import Header from "./components/layout/header";
import DocSign from "./components/doc-sign";
import Footer from "./components/layout/footer";



function App() {

  return (
    <div className="App">
      <Header />
      <main>
        <Router>
          <Routes>
            <Route
              path="/docSign/:signatureID"
              // path="/docSign" 
              element={<DocSign />} ></Route>
          </Routes>
        </Router>
        {/* <DocSign /> */}
      </main>
      <Footer />
    </div >
  );
}

export default App;
