import PoweredByLogo from "../../assets/images/powered-by-logo.png";

function Footer() {
  const imgStyle = {
    height: "45px",
  };
  return (
    <footer>
      {/* <div className="footer-content"> */}
      <img
        src={PoweredByLogo}
        alt="Powered By SembraCare Logo"
        style={imgStyle}
      ></img>
      <p>v0.1.0</p>
      {/* </div> */}
    </footer>
  );
}

export default Footer;
